import React, { useEffect, useState } from 'react';
import './minimumwage.css'; // Import the CSS file

// Rest of the component code...


const MinimumWage = () => {
    useEffect(() => {
      const displayElapsedTimeAndEarnings = () => {
        // Elapsed time calculation
        const startDate = new Date("2022-01-01T00:00:00Z"); // Replace with your start date
  
        setInterval(() => {
          const endDate = new Date(); // Current date and time
  
          const elapsedTime = endDate - startDate;
          let seconds = Math.floor(elapsedTime / 1000);
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);
          let months = Math.floor(days / 30.44);
          let years = Math.floor(months / 12);
  
          seconds %= 60;
          minutes %= 60;
          hours %= 24;
          days %= 30.44;
          months %= 12;
  
          const elapsedTimeString =
            "<div class='time-component'>" +
            "<span class='time-digit'>" + formatTime(years) + "</span>" +
            "<span class='time-label'>Years</span>" +
            "</div>" +
            "<div class='time-component'>" +
            "<span class='time-digit'>" + formatTime(months) + "</span>" +
            "<span class='time-label'>Months</span>" +
            "</div>" +
            "<div class='time-component'>" +
            "<span class='time-digit'>" + formatTime(days) + "</span>" +
            "<span class='time-label'>Days</span>" +
            "</div>" +
            "<div class='time-component'>" +
            "<span class='time-digit'>" + formatTime(hours) + "</span>" +
            "<span class='time-label'>Hours</span>" +
            "</div>" +
            "<div class='time-component'>" +
            "<span class='time-digit'>" + formatTime(minutes) + "</span>" +
            "<span class='time-label'>Minutes</span>" +
            "</div>" +
            "<div class='time-component'>" +
            "<span class='time-digit'>" + formatTime(seconds) + "</span>" +
            "<span class='time-label'>Seconds</span>" +
            "</div>";
  
          document.getElementById("elapsed-time").innerHTML = elapsedTimeString;
  
          // Earnings calculation
          const hourlyWage = 7.25; // Federal minimum wage, change this to the appropriate value
          const secondsWorked = Math.floor(elapsedTime / 1000); // Calculate seconds worked based on elapsed time
  
          const earnings = hourlyWage * (secondsWorked / 3600); // Divide by 3600 to convert seconds to hours
          const earningsString = "$" + earnings.toFixed(2);
          document.getElementById("earnings").innerHTML = earningsString;
        }, 1000); // Update every 1 second
      };
  
      displayElapsedTimeAndEarnings(); // Call the function when the component mounts
  
      // Helper function to format time values
      const formatTime = (time) => {
        return time.toString().padStart(2, '0');
      };
    }, []);
  
    return (
      <div className="container">
        <div id="elapsed-time"></div>
        <div id="earnings"></div>
       </div>
    );
  };
  
  export default MinimumWage;
  